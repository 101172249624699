import React, { useEffect, useState } from 'react';

import { Spin, Typography } from 'antd';
import { Link } from 'gatsby';
import LocalizedStrings from 'react-localization';
import { StringParam, useQueryParam } from 'use-query-params';

import { FormPageLayout } from '../components/Layouts';
import { apiRequester } from '../utility';

const { Title } = Typography;
const strings = new LocalizedStrings({
    en: {
        pleaseHold: 'Please hold while we verify your email',
        emailVerified: 'Your email has been verified. Please proceed to login.',
        couldNotVerify: 'We could not verify your email. Please try again. Reason: {0}',
        backToLogin: 'Back to Log in',
        success: 'Success!',
        oops: 'Oops!',
    },
    de: {
        pleaseHold: 'Bitte warten Sie, während wir Ihre E-Mail-Adresse verifizieren',
        emailVerified: 'Ihre E-Mail-Adresse wurde verifiziert. Bitte loggen Sie sich ein.',
        couldNotVerify:
            'Leider konnten wir Ihre E-Mail-Adresse nicht verifizieren. Bitte versuchen Sie es erneut. Grund: {0}',
        backToLogin: 'Zurück zum Login.',
        success: 'Erfolgreich!!',
        oops: 'Oops! Da ist etwas schiefgelaufen.',
    },
});

const VerifyEmailPage = () => {
    const [status, setStatus] = useState<'success' | 'error' | 'pending'>('pending');
    const [token, setToken] = useQueryParam('t', StringParam);
    const [error, setError] = useState('');
    useEffect(() => {
        apiRequester
            .verifyEmail({ token: token || '' })
            .then(res => {
                console.info(res);
                setStatus('success');
            })
            .catch(err => {
                console.error(err);
                setStatus('error');
                setError(err.extra ? err.extra : err.description);
            });
    }, [setStatus]);
    return (
        <FormPageLayout>
            <div style={{ width: '100%', textAlign: 'center' }}>
                <Title level={2} style={{ marginBottom: '1.5rem' }}>
                    {status === 'pending' ? <Spin /> : status === 'success' ? strings.success : strings.oops}
                </Title>
            </div>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                }}
            >
                {status === 'pending'
                    ? strings.pleaseHold
                    : status === 'success'
                    ? strings.emailVerified
                    : strings.formatString(strings.couldNotVerify!, error ? error : 'UNKNOWN')}
                <br />
                <br />
                <Link to="/login">{strings.backToLogin}</Link>
            </div>
        </FormPageLayout>
    );
};

export default VerifyEmailPage;
